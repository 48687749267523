var __jsx = React.createElement;
import { useBreakpointValue } from '@chakra-ui/react';
import { Box, Button, ChevronDownIcon, ChevronUpIcon, MenuButton } from '@playful/design_system';
import { MenuSubMenu } from '../../components/MenuSubMenu';
import { useNavigationLinks } from '../../user/useNavigationLinks';
import React from 'react';
var ExplorerMenuButton = function ExplorerMenuButton(_ref) {
  var label = _ref.label,
    withArrow = _ref.withArrow,
    isOpen = _ref.isOpen,
    onClose = _ref.onClose,
    onOpen = _ref.onOpen;
  var icon = isOpen ? __jsx(ChevronUpIcon, null) : __jsx(ChevronDownIcon, null);
  var buttonSize = useBreakpointValue({
    base: 'sm',
    lg: 'md'
  }, {
    fallback: 'lg'
  });
  return __jsx(MenuButton, {
    as: Button,
    variant: "ghost",
    colorScheme: "dark",
    rightIcon: withArrow ? icon : undefined,
    onClick: function onClick() {
      return isOpen ? onClose === null || onClose === void 0 ? void 0 : onClose() : onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    },
    size: buttonSize,
    sx: {
      svg: {
        h: 5,
        w: 5
      }
    }
  }, label);
};
export function ExplorerNavigationAppBarSection() {
  var _useNavigationLinks = useNavigationLinks(),
    getNavigationRoutes = _useNavigationLinks.getNavigationRoutes;
  var subRoutes = getNavigationRoutes();
  if (!subRoutes) return null;
  return __jsx(React.Fragment, null, subRoutes.map(function (menuItems, idx) {
    var key = typeof menuItems.label === 'string' ? menuItems.label : idx;
    return __jsx(Box, {
      mr: 3,
      key: key
    }, __jsx(MenuSubMenu, {
      TopMenuButton: ExplorerMenuButton,
      label: menuItems.label,
      items: menuItems.items,
      withArrow: menuItems.withArrow
    }));
  }));
}