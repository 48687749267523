var __jsx = React.createElement;
import { Image, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';
import { Box, Heading, Link, Tag, Text, useIsSmallScreen } from '@playful/design_system';
import { PortableText } from '@portabletext/react';
import React from 'react';
import { urlFor } from '../../sanity';
var variants = {
  major_announcement: {
    background: 'yellow.500',
    tagText: '✧ major announcement!',
    tagTextColor: 'gray.900',
    tagColor: 'white'
  },
  new_feature: {
    background: 'white',
    tagText: 'new feature',
    tagTextColor: '#751EEF',
    tagColor: '#EBD1FE'
  },
  improvement: {
    background: 'white',
    tagText: 'improvement',
    tagTextColor: '#2B84D8',
    tagColor: '#D7F6FE'
  },
  bug_fix: {
    background: 'white',
    tagText: 'bug fix',
    tagTextColor: '#F22C79',
    tagColor: '#FED5D4'
  }
};

// These are passed to the PortableText component to render the rich text
export var textComponents = {
  block: {
    normal: function normal(_ref) {
      var children = _ref.children;
      return __jsx(Text, {
        mt: 3
      }, children);
    }
  },
  list: {
    bullet: function bullet(_ref2) {
      var children = _ref2.children;
      return __jsx(UnorderedList, null, children);
    },
    number: function number(_ref3) {
      var children = _ref3.children;
      return __jsx(OrderedList, null, children);
    }
  },
  listItem: function listItem(_ref4) {
    var children = _ref4.children;
    return __jsx(ListItem, null, children);
  },
  marks: {
    link: function link(_ref5) {
      var children = _ref5.children,
        value = _ref5.value;
      return __jsx(Link, {
        href: value === null || value === void 0 ? void 0 : value.href,
        target: '_blank',
        isExternal: true
      }, children);
    }
  }
};
export function NoticeItem(_ref6) {
  var item = _ref6.item;
  var variant = variants[item.cardType] || {};
  var isSmScreen = useIsSmallScreen(true);
  var imageWidth = 324;
  var imageUrl = item.image && urlFor(item.image).width(imageWidth).url();
  return __jsx(Box, {
    padding: 6,
    background: variant.background,
    borderBottom: "1px solid",
    borderColor: "gray.100"
  }, __jsx(Tag, {
    fontSize: "xs",
    minH: "22px",
    w: "fit-content",
    px: 1.5,
    py: 0.5,
    color: variant.tagTextColor,
    background: variant.tagColor
  }, variant.tagText), __jsx(Box, {
    mt: 4,
    flexWrap: "wrap",
    display: "flex",
    gap: 6,
    justifyContent: "space-between"
  }, __jsx(Box, {
    flex: isSmScreen ? '1 1 auto' : 1
  }, __jsx(Heading, {
    as: "h2",
    lineHeight: 7,
    fontSize: 18,
    color: "gray.700",
    fontWeight: "bold",
    mb: 3
  }, item.title), __jsx(Text, {
    as: "div",
    color: "gray.700"
  }, __jsx(PortableText, {
    value: item.bodyRichText,
    components: textComponents
  })), item.learnMoreUrl && __jsx(Link, {
    href: item.learnMoreUrl,
    isExternal: true,
    fontSize: "sm",
    variant: "underline",
    color: "gray.700"
  }, "Learn More")), imageUrl && __jsx(Box, {
    flex: isSmScreen ? '1 1 auto' : 1
  }, __jsx(Image, {
    src: imageUrl,
    alt: item.title,
    width: imageWidth,
    minW: "100%",
    border: "1px solid",
    borderColor: "gray.900",
    borderRadius: "md"
  }))));
}